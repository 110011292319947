import React, { useState, useEffect, useRef } from "react";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import "./SpreadsheetDocument.css";

function SpreadsheetDocument(props) {
  const [error, setError] = useState(null);
  const [html, setHtml] = useState(null);
  const [json, setJson] = useState(null);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(props["location-intro"])
      .then((res) => res.text())
      .then(
        (result) => {
          setHtml(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      );
  }, [props]);

  useEffect(() => {
    fetch(props["location-data"])
      .then((res) => res.json(), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(
        (result) => {
          setJson(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      );
  }, [props]);

  useEffect(() => {
    var contentElement = document.getElementById("content");
    contentElement
      .querySelectorAll("table")
      .forEach((el) => el.classList.add("table", "table-dark"));
  }, [html]);

  function ContentLink(props) {
    if (props.link) {
      return (
        <a target="_blank" href={props.link} className="icon-link">
          Link »
        </a>
      );
    } else {
      return <span>&lt;Link to be available soon, stay tuned&gt;</span>;
    }
  }

  function Repeat(props) {
    return (
      <div className={`feature col py-3 ${props.value.link ? "" : "disabled"}`}>
        <div className="py-2">
          <h3>{props.value.title}</h3>
        </div>
        <p>{props.value.abstract}</p>
        <ul>
          <li>Level: {props.value.level}</li>
          <li>Duration: {props.value.duration} Minutes</li>
        </ul>
        <ContentLink link={props.value.link} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <div className="row g-4 row-cols-1 row-cols-lg-1">
          {json &&
            json.data.Episodes.map((episode, index) => (
              <Repeat key={index} value={episode} />
            ))}
        </div>
      </div>
    );
  }
}

export default SpreadsheetDocument;
