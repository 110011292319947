import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";
import HtmlDocument from "./components/HtmlDocument";
import SpreadsheetDocument from "./components/SpreadsheetDocument";

function App() {
  useEffect(() => {
    document.body.classList.remove("is-preload");
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <SideBar />
      <main role="main" className="mx-auto">
        <div id="content" className="container">
          <Routes>
            <Route
              path="/"
              element={<HtmlDocument location="/content/welcome.html" />}
            />
            <Route
              path="/microsoft"
              element={
                <SpreadsheetDocument
                  location-intro="/content/microsoft.html"
                  location-data="/content/microsoft.json"
                />
              }
            />
            <Route
              path="/sap"
              element={
                <SpreadsheetDocument
                  location-intro="/content/sap.html"
                  location-data="/content/sap.json"
                />
              }
            />
            <Route
              path="/vmware"
              element={
                <SpreadsheetDocument
                  location-intro="/content/vmware.html"
                  location-data="/content/vmware.json"
                />
              }
            />
            <Route
              path="/mainframes"
              element={
                <SpreadsheetDocument
                  location-intro="/content/mainframes.html"
                  location-data="/content/mainframes.json"
                />
              }
            />
            <Route
              path="/industrysolutions"
              element={
                <SpreadsheetDocument
                  location-intro="/content/industrysolutions.html"
                  location-data="/content/industrysolutions.json"
                />
              }
            />
          </Routes>
        </div>
        <Footer />
      </main>
    </Router>
  );
}

export default App;
