import "./SideBar.css";
import React from "react";
import { NavLink } from "react-router-dom";

function SideBar() {
  function isValidUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  }

  function onlyLettersAndDigits(string) {
    return string.match("^[a-zA-Z0-9]+$");
  }

  function checkAccess(e) {
    e.preventDefault();

    var errorMessage = "Invalid password, please contact us for assistance.";
    var password = prompt(
      "Please enter the password for Proposals and Business Desk access"
    );

    var trimmedPassword = password.trim();
    if (
      trimmedPassword == null ||
      !onlyLettersAndDigits(trimmedPassword) ||
      trimmedPassword.length > 25
    ) {
      window.alert(errorMessage);
      return;
    }

    fetch(`/content/${password.trim()}.txt`)
      .then((res) => res.text())
      .then(
        (result) => {
          if (isValidUrl(result)) {
            window.open(result, "_blank");
          } else {
            window.alert(errorMessage);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          window.alert(errorMessage);
        }
      );
  }

  return (
    <section id="sidebar">
      <div className="inner">
        <div className="logo">
          <img src="/logo.svg" />
        </div>
        <nav>
          <ul>
            <li>
              <NavLink end to="/" className="nav-link" activeClassName="active">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-house"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                  />
                </svg>
                &nbsp; Welcome
              </NavLink>
            </li>
          </ul>
          <div className="heading">Workloads in Pieces</div>
          <ul>
            <li>
              <NavLink
                to="microsoft"
                className="nav-link"
                activeClassName="active"
              >
                Microsoft
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="sap" className="nav-link" activeClassName="active">
                SAP
              </NavLink>
            </li>
            <li>
              <NavLink
                to="vmware"
                className="nav-link"
                activeClassName="active"
              >
                VMWare
              </NavLink>
            </li>
            <li>
              <NavLink
                to="mainframes"
                className="nav-link"
                activeClassName="active"
              >
                Mainframes
              </NavLink>
            </li>
            <li>
              <NavLink
                to="industrysolutions"
                className="nav-link"
                activeClassName="active"
              >
                Industry Solutions
              </NavLink>
            </li> */}
          </ul>
          <div className="heading">Business Desk Resources</div>
          <ul className="resources">
            <li>
              <a href="javascript:;" onClick={checkAccess}>
                Proposals and Business Desk (Password Required)
              </a>
            </li>
            <li>
              <a
                href="https://partners.awscloud.com/Microsoft-PSA-Request.html"
                target="_blank"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default SideBar;
